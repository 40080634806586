.main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #f4f6fa;
  overflow: auto;

  .lang {
    display: flex;
    position: absolute;
    right: 1%;
    top: 1%;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 65px;
    height: 50px;
    font-size: 10pt;
    color: #546de5;
    border: 1px solid #546de5;
    border-radius: 10px;
    text-decoration: none;
  }

  .lang:hover {
    background-color: #546de5;
    color: white;
    border: none;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 1100px;
    height: 700px;
    background-color: #e9ecef;
    border-radius: 10px;

    .blockOne {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 77px;

      .text {
        display: flex;
        align-items: center;
        width: 350px;
        height: 100%;
        padding-left: 25px;
        font-size: 12pt;
        color: black;
        font-weight: bold;
      }

      .enter {
        display: flex;
        align-items: center;
        width: 700px;
        height: 77px;

        .inpE {
          display: flex;
          width: 100%;
          height: 27px;
          border-radius: 10px;
          background-color: white;
          border: 2px solid #de4843;
        }

        .inp {
          display: flex;
          width: 100%;
          height: 27px;
          border-radius: 10px;
          background-color: white;
          border: none;
        }

        .inp:focus {
          border: 2px solid #0977f3;
          color: black;
          outline: none;
        }

        .inpE:focus {
          border: 2px solid #0977f3;
          color: black;
          outline: none;
        }

        .inpE::placeholder {
          text-align: center;
          color: gray;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .block {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 103px;

      .text {
        display: flex;
        align-items: center;
        width: 350px;
        height: 100%;
        padding-left: 25px;
        font-size: 12pt;
        color: black;
        font-weight: bold;
      }

      .enter {
        display: flex;
        align-items: center;
        width: 700px;
        height: 100px;

        .inpE {
          display: flex;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          background-color: white;
          border: 2px solid #de4843;
        }

        .inp {
          display: flex;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          background-color: white;
          border: none;
        }

        .inp:focus {
          color: black;
          outline: none;
        }
        
        .inpE:focus {
          border: 2px solid #0977f3;
          color: black;
          outline: none;
        }

        .inpE::placeholder {
          text-align: center;
          color: gray;
        }
      }

      .checks {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;

        .check {
          display: flex;
          width: 30px;
          height: 30px;
        }

        .check:hover {
          cursor: pointer;
        }
      }

      .boxes {
        display: grid;
        grid-template-columns: 300px 300px 100px;
        align-content: center;
        width: 100%;
        height: 60px;
        background-color: white;
        border-radius: 10px;

        .up {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-left: 6px;
          width: 100%;
          height: 100%;

          .ups:hover {
            cursor: pointer;
          }
        }
      }
    }

    .blocks {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 306px;

      .texts {
        display: flex;
        padding-top: 55px;
        width: 350px;
        height: 100%;
        padding-left: 25px;
        font-size: 12pt;
        color: black;
        font-weight: bold;
      }

      .text {
        display: flex;
        align-items: center;
        width: 350px;
        height: 100%;
        padding-left: 25px;
        font-size: 12pt;
        color: black;
        font-weight: bold;
      }

      .enter {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        align-items: center;
        width: 700px;
        height: 306px;
  
        .linkscontent {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          height: 100%;
          gap: 10px;

          .inps {
            display: flex;
            width: 662px;
            height: 50px;
            background-color: white;
            border-radius: 10px;
            border: none;
          }

          .inpsE {
            display: flex;
            width: 662px;
            height: 50px;
            background-color: white;
            border-radius: 10px;
            border: 2px solid #de4843;
          }
  
          .inps:focus {
            border: 2px solid #0977f3;
            color: black;
            outline: none;
          }

          .inpsE:focus {
            border: 2px solid #0977f3;
            color: black;
            outline: none;
          }

          .inpsE::placeholder {
            text-align: center;
            color: gray;
          }

          .inps::placeholder {
            text-align: center;
            color: gray;
          }

          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10pt;
            color: #546de5;
            width: 25px;
            height: 25px;
            background-color: #f4f6fa;
            border-radius: 10px;
            border: 1px solid #546de5;
          }
    
          .btn:hover {
            background-color: #546de5;
            color: white;
            border: none;
            cursor: pointer;
          }
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 82px;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 25px;
        margin-top: 7px;
        font-size: 10pt;
        color: #546de5;
        width: 150px;
        height: 50px;
        background-color: #f4f6fa;
        border-radius: 10px;
        border: 1px solid #546de5;
      }

      .btn:hover {
        background-color: #546de5;
        color: white;
        border: none;
        cursor: pointer;
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 25px;
        margin-top: 7px;
        font-size: 10pt;
        color: #546de5;
        width: 150px;
        height: 50px;
        background-color: #f4f6fa;
        border-radius: 10px;
        border: 1px solid #546de5;
      }

      .btns:hover {
        background-color: #546de5;
        color: white;
        border: none;
        cursor: pointer;
      }

      .btnsoff {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 7px;
        margin-right: 25px;
        font-size: 10pt;
        color: #546de5;
        width: 150px;
        height: 50px;
        background-color: #f4f6fa;
        border-radius: 10px;
        border: 1px solid #546de5;
      }
    }
  }
}