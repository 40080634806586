.main {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f4f6fa;

  .content {
    display: grid;
    grid-template-rows: 1f 1fr;
    width: 350px;
    height: 150px;
    background-color: white;
    border-radius: 15px;

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 12pt;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 12pt;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 30px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
      }
    }
  }
}