.main {
	width: 100vw;
	height: 100vh;
	background-color: #f4f6fa;
	overflow: auto;

	.pass {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		background-color: #f4f6fa;
	
		.contents {
			display: grid;
			grid-template-rows: 50px 50px 50px;
			width: 450px;
			height: 150px;
			background-color: white;
			border-radius: 15px;
	
			.texts {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				font-size: 12pt;
			}

			.inp {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				font-size: 12pt;

				.inps {
					display: flex;
					align-items: center;
					width: 250px;
					height: 30px;
					border-radius: 10px;
					border: 1px solid black;
				}

				.inps:focus {
					outline: none;
				}
			}
	
			.buttonss {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				font-size: 12pt;
	
				.btns {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 50px;
					height: 30px;
					border-radius: 10px;
					border: none;
					cursor: pointer;
				}
			}
		}
	}

	.table {
		display: flex;
		flex-direction: column;
		width: 1500px;
		height: 98%;
		margin: 1%;

		details summary {
			position: relative;
			width: 0;
			height: 0;
			transform: translateX(1460px) translateY(-40px);
			list-style-image: url('./images/choose.png');
			cursor: pointer;
		}

		details[open] summary  {
			list-style-image: url('./images/chooses.png');
		}

		.headtr {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			height: 50px;
			border: 1px solid #0075ff;

			.headtd {
				display: flex;
				align-items: center;
				padding-left: 10px;
				font-size: 12pt;
				width: 350px;
				height: 50px;
				color: black;
				font-weight: bold;
				border-left: 1px solid #0075ff;
			}

			.heademptys {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12pt;
				width: 50px;
				height: 50px;
				color: black;
				font-weight: bold;
				border: none;
			}

			.heademptye {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12pt;
				width: 50px;
				height: 50px;
				color: black;
				border: none;
			}
		}

		.bodytr {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			height: 50px;
			margin-bottom: 4px;
			margin-top: 10px;
			border-top: 1px solid #0075ff;

			.bodytd {
				display: flex;
				align-items: center;
				padding-left: 10px;
				font-size: 12pt;
				width: 350px;
				height: 50px;
				color: black;
				border-left: 1px solid #0075ff;
				border-bottom: 1px solid #0075ff;
				overflow: auto;
			}

			.bodyemptys {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12pt;
				width: 50px;
				height: 50px;
				color: black;
				border-left: 1px solid #0075ff;
				border-bottom: 1px solid #0075ff;
			}

			.bodyemptye {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12pt;
				width: 50px;
				height: 50px;
				color: black;
				border-left: 1px solid #0075ff;
				border-right: 1px solid #0075ff;
				border-bottom: 1px solid #0075ff;
			}
		}

		.bodytrcontent {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			height: 50px;

			.bodycontent {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding-right: 10px;
				font-size: 12pt;
				width: 700px;
				height: 50px;
				color: black;
				border-bottom: 1px solid #0075ff;
				border-left: 1px solid #0075ff;
			}

			.bodycontents {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding-left: 10px;
				font-size: 12pt;
				width: 700px;
				height: 50px;
				color: black;
				border-bottom: 1px solid #0075ff;
				border-right: 1px solid #0075ff;
				border-left: 1px solid #0075ff;
			}

			.bodyemptys {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12pt;
				width: 50px;
				height: 50px;
				color: black;
			}

			.bodyemptye {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12pt;
				width: 50px;
				height: 50px;
				color: black;
			}
		}

		.bodytrcontentjs {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			height: 120px;

			.bodycontentjs {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				padding-right: 25px;
				font-size: 12pt;
				width: 685px;
				height: 120px;
				color: black;
				border-bottom: 1px solid #0075ff;
				border-left: 1px solid #0075ff;
			}

			.bodycontentsjs {
				display: flex;
				flex-direction: column;
				padding-left: 10px;
				justify-content: space-around;
				font-size: 12pt;
				width: 700px;
				height: 120px;
				color: black;
				border-bottom: 1px solid #0075ff;
				border-right: 1px solid #0075ff;
				border-left: 1px solid #0075ff;
				overflow: auto;
			}

			.bodyemptysjs {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12pt;
				width: 50px;
				height: 120px;
				color: black;
			}

			.bodyemptyejs {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12pt;
				width: 50px;
				height: 120px;
				color: black;
			}
		}
	}
}